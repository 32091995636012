<template>
  <button
    id="btn-refresh"
    class="font-common tnt-btn tnt-btn-green tnt-btn-fat"
    @click="refresh($event)"
    v-bind:style="styleObject"
  >
    {{ btnText }}
  </button>
</template>

<script>
export default {
  name: "UpdateAppBtn",
  data: () => ({
    styleObject: {},
  }),
  props: ["alwaysShow", "btnText"],
  created() {
    this.initialize();
  },
  methods: {
    initialize() {

      if (this.alwaysShow == "true") {
        this.styleObject = {
          display: "inline-block",
        };
      }
    },

    refresh(e) {
      e.disabled = true;
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });
      location.reload();
    },
  },
};
</script>
<style scoped>
#btn-refresh {
  display: none;
}
</style>